<template>
  <a-table
    :columns="columns"
    :rowKey="record => record.Id"
    :dataSource="data"
    :pagination="pagination"
    :loading="loading"
    @change="handleTableChange"
  >
      <span slot="action" slot-scope="item">
        <a-button-group>
          <a-button type="primary">
            <router-link :to="{ name: 'measurements-edit', params: { measurementId: item.Id } }">
              Редактировать
            </router-link>
          </a-button>
          <!-- <a-popconfirm
                      title="Действительно собираетесь удалить запись?"
                      ok-text="Yes"
                      cancel-text="No"
                      @confirm="destroy(item)"
                    >
            <a-button type="danger">
              Удалить
            </a-button>
          </a-popconfirm> -->
        </a-button-group>
      </span>
  </a-table>
</template>
<script>

import { sortQuery } from '../../helpers';

export default {
  data() {
    return {
      data: [],
      pagination: {},
      loading: false,
      page: 1,
      columns: [
        {
          title: 'Наименование Единицы',
          dataIndex: 'Name',
          scopedSlots: { customRender: 'Name' },
          sorter: true,
        },
        {
          title: 'Действие',
          key: 'action',
          scopedSlots: { customRender: 'action' },
        },
      ],
    };
  },
  created() {
    this.fetch();
  },
  methods: {
    handleTableChange(pagination, filters, sorter) {
      this.page = pagination.current;
      this.pagination = {
        ...this.pagination,
        current: pagination.current,
      };
      this.fetch({
        results: pagination.pageSize,
        page: pagination.current,
        sort: sortQuery(sorter),
        ...filters,
      });
    },
    destroy(item) {
      this.loading = true;
      this.$api.deleteMeasurement(item, () => {
        this.fetch();
        this.loading = false;
      });
    },
    fetch(params = {}) {
      this.loading = true;

      this.$api.getMeasurements({ page: this.page, ...params }, ({ data }) => {
        this.loading = false;
        this.data = data.data;
        this.pagination = {
          pageSize: data.meta.per_page,
          current: data.meta.current_page,
          total: data.meta.total,
        };
      }, () => {
        this.loading = false;
      });
    },
  },
};
</script>
